const namespaced = true
import api from '../../api/transfers.js';
import router from '../../routes/index.js';

const state = {
    transfer: {
        observations: "",
        date: "",   // Se abre la posibilidad de generar transferencias programadas.
        origin: {   // Almacen de origen (principal)
            id: null
        },
        destination: { // Almacen destino (consignación).
            id : null
        },
        user : {    // Usuario que recibe.
           id : null 
        },
        items: [

        ]
    },
}
const mutations = {
    MUTATION_SET_TRANSFER(state, obj){
         state.transfer     = obj;
    },
    MUTATION_SET_ITEM_LINE(state, item){
        state.transfer.items.push(item);
    },
    MUTATION_SET_TRANSFER_RESET(state){
         state.transfer = { observations: "", date: "", origin: { id: null }, destination: { id : null }, user : { id : null }, items: [ ] };
    },
    MUTATION_REMOVE_ITEM(state,item){
        state.transfer.items.splice(state.transfer.items.indexOf(item),1);
    },
    MUTATION_SET_USER_ID(state, id){
        state.transfer.user.id = id;
    }
}
const actions = {    
    async addLine(context,item) {        
        var isAction = true;

        for(var i = 0; i< context.state.transfer.items.length; i++){
            if(context.state.transfer.items[i].id === item.id ) {
                isAction = false;
            }
        }
        if(isAction){
            await context.commit('MUTATION_SET_ITEM_LINE', {
                'id': item.id, 
                'name': item.name, 
                'type': item.type, 
                'description': item.description, 
                'warehouse' : item.inventory.warehouses.name,
                'quantity' : 0,
                'available' : item.inventory.warehouses.availableQuantity,
                'unit': item.inventory.unit,
                'price' : item.price[1].price,              // Se establece esta posición en el arreglo para acceder a la lista de precios de consignación.
                'currency' : item.price[1].currency.code,
                'percentage' : item.tax[0].percentage,
                'taxName' : item.tax[0].name,
                'MaxLimit' : item.inventory.warehouses.availableQuantity
            });
        }
    },
    addLineRecover(context,item) {   
        var isAction = true;
        for(var i = 0; i < context.state.transfer.items.length; i++) {
            if(context.state.transfer.items[i].id === item.producto_id ) {
                isAction = false;
            }
        }
        if(isAction){
            context.commit('MUTATION_SET_ITEM_LINE', {
                'id': item.producto_id, 
                'name': item.producto_nombre, 
                'type': 'product', 
                'description': null, 
                'warehouse' : 2,
                'quantity' : item.producto_cantidad,
                'available' : item.producto_cantidad,
                'unit': item.producto_unidad,
                'price' : item.producto_precio_venta,
                'currency' : item.producto_moneda,
                'percentage' : item.producto_impuesto,
                'taxName' : item.producto_impuesto_nombre,
                'MaxLimit' : item.producto_cantidad
            });
        }
    },
    async removeItem(context,item){
        await context.commit("MUTATION_REMOVE_ITEM", item);
    },
    async create(context){
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Creando transferencia...' }, { root: true });
            
            const res = await api.create(context.state.transfer);
            await context.commit('MUTATION_SET_TRANSFER_RESET');
            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });

            await context.dispatch('almacenes/getAlmacenes', { }, { root: true })
            
            return res.data;
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al registrar la transferencia (FE)'}, { root: true });
        }
    },
    async recover(context){
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Recuperando inventario...' }, { root: true });
            
            const res = await api.recover(context.state.transfer);
            await context.commit('MUTATION_SET_TRANSFER_RESET');
            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            router.push('/socios');
            await context.dispatch('almacenes/getAlmacenes', { }, { root: true })
            
            return res.data;
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al registrar la transferencia (FE)'}, { root: true });
        }
    },
    setUserId(context) {
        context.commit('MUTATION_SET_USER_ID', context.rootState.socios.socio.id); 
    },
    reset(context){
        context.commit('MUTATION_SET_TRANSFER_RESET');
    }
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}